import { z } from "zod";
import { MESSAGE } from "../../constants/errorMessages";
import { TUser } from "./user";

export type TQueryClubs = {
  data: TClubSimple[];
  page: number;
  perPage: number;
  total: number;
};

export type TClubDetail = {
  id: string;
  name: string;
  shortCode: string;
  email: string;
  phone: string;
  federationId: string;
  federationCode: string;
  federationName: string;
  description: string;
  latitude: number | null;
  longitude: number | null;
  socialMedia: {
    id: string;
    clubId: string | null;
    instagramUrl: string | null;
    facebookUrl: string | null;
    whatsappUrl: string | null;
    twitterUrl: string | null;
    youtubeUrl: string | null;
    tiktokUrl: string | null;
    spotifyUrl: string | null;
    ituneUrl: string | null;
    soundcloudUrl: string | null;
  } | null;
  validatedPending: boolean | null;
  isActive: boolean | number; // Puede ser 1 o true
  lastModifiedAt: string; // Cambiado a string para reflejar "2024-11-27T19:36:27.000Z"
  address: {
    id: string;
    address: string;
    countryCode: string;
    autonomyCode: string;
    provinceCode: string;
    municipalityCode: string;
    postalCode: string;
  };
  users: {
    id: string;
    name: string;
    surname: string;
    email: string;
    type: string;
  }[];
  createdAt: string; // Nuevo campo basado en los datos
  mapsUrl: string | null; // Nuevo campo basado en los datos
  website: string | null; // Nuevo campo basado en los datos
  logoUrl: string | null; // Nuevo campo basado en los datos
  taxName: string | null; // Nuevo campo basado en los datos
  iban: string | null; // Nuevo campo basado en los datos
  cif: string | null; // Nuevo campo basado en los datos
  weekSchedule: any | null; // Nuevo campo basado en los datos (estructura no especificada)
};


export type TClubSimple = {
  id: string;
  name: string;
  email: string;
  phone: string;
  federationId: string;
  description: string;
  website: string;
  logoUrl: string;
  isActive: boolean;
  cityName: string;
  deportistas: number;
  entrenadores: number;
  events: number;
};

export type TClubList = {
  id: string;
  name: string;
};

export const addClubStep0 = z.object({
  name: z.string().optional(),
  shortCode: z.string().optional(),
  description: z.string().optional(),
  email: z.string().email({ message: MESSAGE.EMAIL }).optional(),
  phone: z.string().optional(),
  webSite: z.string().optional(),
  address: z.object({
    id: z.string().optional(),
    address: z.string().min(1, { message: MESSAGE.REQUIRED }),
    countryCode: z.string().min(1, { message: MESSAGE.REQUIRED }),
    autonomyCode: z.string().min(1, { message: MESSAGE.REQUIRED }),
    provinceCode: z.string().min(1, { message: MESSAGE.REQUIRED }),
    municipalityCode: z.string().min(1, { message: MESSAGE.REQUIRED }),
    postalCode: z.string().min(1, { message: MESSAGE.REQUIRED }),
  }),
  isFiscalAddressSameAsAddress: z.boolean().optional(),
  fiscalAddress: z.object({
    id: z.string().optional(),
    address: z.string().optional(),
    countryCode: z.string().optional(),
    autonomyCode: z.string().optional(),
    provinceCode: z.string().optional(),
    municipalityCode: z.string().optional(),
    postalCode: z.string().optional(),
  }),
  taxName: z.string().optional(),
  iban: z.string().optional(),
  cif: z.string().optional(),
  logoUrl: z.string().optional(),
});

export const addClubSchema = z.object({
  ...addClubStep0.shape,
  weekSchedule: z.record(z.array(z.object({ start: z.string(), end: z.string() }))).optional(),
  boardMembers: z
    .array(
      z.object({
        avatar: z.string().optional(),
        name: z.string().optional(),
        lastName: z.string().optional(),
        position: z.string().optional(),
      })
    )
    .optional(),
  socialMedia: z.object({
    instagramUrl: z.string().optional().nullable().default(null),
    twitterUrl: z.string().optional().nullable().default(null),
    whatsappUrl: z.string().optional().nullable().default(null),
    youtubeUrl: z.string().optional().nullable().default(null),
    tiktokUrl: z.string().optional().nullable().default(null),
    facebookUrl: z.string().optional().nullable().default(null),
    id: z.string().optional(),
  }),
  users: z
    .array(
      z.object({
        name: z.string().min(1, { message: MESSAGE.REQUIRED }),
        surname: z.string().min(1, { message: MESSAGE.REQUIRED }),
        email: z.string().min(1, { message: MESSAGE.REQUIRED }).email({ message: MESSAGE.EMAIL }),
        type: z.string().optional(),
        id: z.string().optional(),
      })
    )
    .optional(),

  latitude: z.number().optional(),
  longitude: z.number().optional(),
});

export type TClub = z.infer<typeof addClubSchema>;
