import React, { useEffect, useState } from "react";
import "./event-view.scss";
import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Container,
    Grid,
    Typography,
    Chip,
    Stack,
    Button,
} from "@mui/material";
import { useNavigate, useParams, Link } from "react-router-dom";
import useQueryEventId from "../../../utils/hooks/useQueryEventById";
import useSetPageName from "../../../utils/hooks/useSetPageName";
import StocksTable from "../../../components/eventCard/stocksTable/StocksTable";

const EventView = () => {
    useSetPageName([
        { name: "Gestor de eventos", path: "/gestor_eventos" },
        {
            name: "Vista del evento ",
            path: "/gestor_eventos/agregar",
        },
    ]);

    const navigate = useNavigate();
    const { id: eventId } = useParams();
    const { eventData, activate, isLoading, isRefetching } = useQueryEventId(eventId);
    const [isLive, setIsLive] = useState(false);

    useEffect(() => {
        if (eventId) {
            activate();
        }
    }, [eventId, activate]);

    useEffect(() => {
        if (eventData) {
            const now = new Date();
            const startDate = new Date(eventData.startDate);
            const endDate = new Date(eventData.endDate);
            setIsLive(now >= startDate && now <= endDate);
        }
    }, [eventData]);

    if (isLoading || isRefetching) {
        return <Typography variant="h6">Cargando...</Typography>;
    }

    if (!eventData) {
        return <Typography variant="h6">Evento no encontrado</Typography>;
    }

    return (
        <Container maxWidth="lg" className="event-view-container">
            {/* Header del evento */}
            <Box className="event-header" sx={{ display: "flex", alignItems: "center", py: 5, gap: 4 }}>
            <Box
                    sx={{
                        flex: "0 0 40%",
                        maxWidth: "40%",
                        aspectRatio: "3/4", // Proporción de A4
                        overflow: "hidden",
                        borderRadius: "8px",
                    }}
                >
                    <CardMedia
                        component="img"
                        image={eventData.posterUrl}
                        alt={`Poster del evento ${eventData.name}`}
                        sx={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                        }}
                    />
                </Box>
                <Box sx={{ flex: "1", textAlign: "left" }}>
                    <Typography variant="h3" component="h1" gutterBottom>
                        {eventData.name}
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 2 }}>
                        {eventData.description}
                    </Typography>
                </Box>
            </Box>

            {/* Botones de acciones */}
            <Box sx={{ textAlign: "center", my: 3 }}>
                <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/inscripciones/${eventId}`}
                    sx={{ mx: 1 }}
                >
                    Inscripciones
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    href={`https://www.app.rfejudo.com/events/live/${eventId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ mx: 1, animation: isLive ? "pulse 1.5s infinite" : "none" }}
                    disabled={!isLive}
                >
                    Ir al Directo
                </Button>
            </Box>

            {/* Información clave */}
            <Grid container spacing={4} className="event-info-grid">
                <Grid item xs={12} md={4}>
                    <Card className="info-card">
                        <CardContent>
                            <Typography variant="h6">Fecha y Hora</Typography>
                            <Typography variant="body2">
                                Inicio: {new Date(eventData.startDate).toLocaleString()}
                            </Typography>
                            <Typography variant="body2">
                                Fin: {new Date(eventData.endDate).toLocaleString()}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card className="info-card">
                        <CardContent>
                            <Typography variant="h6">Ubicación</Typography>
                            <Typography variant="body2">{eventData.location}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card className="info-card">
                        <CardContent>
                            <Typography variant="h6">Organización</Typography>
                            <Typography variant="body2">{eventData.organizatorName}</Typography>
                            <Typography variant="body2">
                                Tipo: {eventData.organizatorType}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {/* Descripción y detalles */}
            <Box className="event-details" sx={{ py: 5 }}>
                <Typography variant="h5" gutterBottom>
                    Detalles del Evento
                </Typography>
                <Typography variant="body1" sx={{ mb: 3 }}>
                    {eventData.description}
                </Typography>

                {/* Extras */}
                <Stack direction="row" spacing={2} className="extras">
                    <Chip label={eventData.picnic ? "Picnic disponible" : "Sin picnic"} />
                    <Chip label={eventData.streaming ? "Streaming activo" : "Sin streaming"} />
                </Stack>
            </Box>

            {/* Stocks por categoría */}
            <Box className="event-stocks" sx={{ py: 5 }}>
                <Typography variant="h5" gutterBottom>
                    Categorías y Disponibilidad
                </Typography>
                {eventData.stocks && eventData.stocks.length > 0 ? (
                    <Box>
                        <Typography className="eventcard-info">Stock: </Typography>
                        <StocksTable stocks={eventData.stocks} />
                    </Box>
                ) : null}
            </Box>
        </Container>
    );
};

export default EventView;
