import { Cancel, KeyboardBackspace } from "@mui/icons-material";
import { Container, Grid, IconButton, Paper, Typography } from "@mui/material";
import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import Button from "../../components/button/Button";
import { toastMessageSuccess } from "../../components/common/toastMessage";
import TextInput from "../../components/textInput/TextInput";
import { MESSAGE } from "../../constants/errorMessages";
import { resetPassword } from "../../context/auth/authActions";
import { useAppDispatch } from "../../context/hooks/hooks";
import "./reset-password.scss";

const ResetPassword = () => {
  const formSchema = z.object({
    password: z
      .string()
      .superRefine((value, ctx) => {
        if (!/(?=.*[A-Z])/.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "La contraseña debe contener al menos una letra mayúscula.",
          });
        }

        if (!/(?=.*\d)/.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "La contraseña debe contener al menos un número.",
          });
        }

        if (!/(?=.*[@$!%*?&._-])/.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "La contraseña debe contener al menos un carácter especial (@, $, !, %, *, ?, &, ., _, -).",
          });
        }

        if (value.length < 7) {
          ctx.addIssue({
            code: "too_small",
            minimum: 7,
            inclusive: true, // Indica que 7 caracteres son válidos
            type: "string",
            message: "La contraseña debe tener al menos 7 caracteres.",
          });
        }
      }),
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleBackButton = () => {
    navigate(-1);
  };

  const handleResetPassword = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const password = formData.get("password")?.toString() || "";
    const repeatPassword = formData.get("repeatPassword")?.toString() || "";

    if (password !== repeatPassword) {
      setErrors({ repeatPassword: "Las contraseñas no coinciden." });
      return;
    }

    try {
      formSchema.parse({ password });
      const body = {
        password: password,
        token: new URLSearchParams(window.location.search).get("token") || "",
      };
      const response = await dispatch(resetPassword(body));
      if (response.payload !== "success") {
        setErrors({ noValid: response.payload });
      } else {
        toastMessageSuccess("Contraseña cambiada con éxito.");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    } catch (error) {
      if (error instanceof z.ZodError) {
        const validationErrors: Record<string, string> = {};
        error.errors.forEach((err) => {
          if (err.path) {
            validationErrors[err.path[0]] = err.message;
          }
        });
        setErrors(validationErrors);
      } else {
        setErrors({ noValid: "Ocurrió un error al procesar la solicitud." });
      }
    }
  };

  return (
    <Container className="reset-container">
      <Paper className="reset-paper">
        <Container className="reset-logo" />
        <Container className="reset-title-container">
          <IconButton className="reset-back-button" onClick={handleBackButton}>
            <KeyboardBackspace className="reset-back-icon" />
          </IconButton>
          <Typography className="reset-title" component="h1" variant="h5">
            Establecer nueva contraseña
          </Typography>
          <div className="reset-back-button"></div>
        </Container>
        <form onSubmit={handleResetPassword} className="form">
          <TextInput
            name="password"
            required
            id="password"
            title="Establecer nueva contraseña"
            autoComplete="password"
            autoFocus
            minWidth="full-width"
            placeholder="Establecer nueva contraseña"
            defaultValue=""
            className="reset-password-input"
          />
          <TextInput
            name="repeatPassword"
            required
            id="repeatPassword"
            title="Repetir nueva contraseña"
            autoComplete="repeatPassword"
            autoFocus
            minWidth="full-width"
            placeholder="Repetir nueva contraseña"
            defaultValue=""
          />
          {Object.values(errors).map((error, i) => (
            <Typography
              key={`error-${i}`}
              className="reset-error-text"
              component="p"
              sx={{ display: "flex", mt: 1 }}
            >
              <Cancel /> {error}
            </Typography>
          ))}
          <Button
            size="large"
            type="submit"
            width="fit-content"
            rounded="sm"
            sx={{ mt: 3, mb: 2, backgroundColor: "red" }}
            className="reset-button"
          >
            Confirmar
          </Button>
        </form>
        <Grid className="reset-logos-container">
          <img
            src="images/logos/EU_financiation.png"
            alt="Imagen Financiado por la Unión Europea"
            className="reset-logo-sponsors"
          />
          <img
            src="images/logos/Plan_recuperacion.png"
            alt="Plan de recuperación, Transformación y Resiliencia"
            className="reset-logo-sponsors"
          />
        </Grid>
      </Paper>
    </Container>
  );
};

export default ResetPassword;
