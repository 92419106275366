import {
  Apartment,
  BarChart,
  Engineering,
  Event,
  Groups,
  Home as HomeIcon,
  HowToVote,
  Store,
} from "@mui/icons-material";

import SportCard from "../components/users/sportCard/SportCard";
import { ROLES } from "../constants/constants";
import AdminsManager from "../pages/adminsManager/AdminsManager";
import AddEditAdmin from "../pages/adminsManager/addAdmin/AddAdmin";
import Clubs from "../pages/clubs/Clubs";
import CreateNewClub from "../pages/clubs/addClub/AddClub";
import EventsManager from "../pages/eventsManager/EventsManager";
import CreateEditEvent from "../pages/eventsManager/addEvent/AddEvent";
import Federations from "../pages/federations/Federations";
import AddFederation from "../pages/federations/addFederation/AddFederation";
import Home from "../pages/home/Home";
import InscriptionEvent from "../pages/inscriptions/InscriptionEvent";
import UsersManager from "../pages/usersManager/UsersManager";
import AddEditUser from "../pages/usersManager/addUser/AddUser";
import Ranking from "../pages/rankings/Rankings";
import CreateEditEventSimple from "../components/events/simple/AddSimple";
import MailVote from "../pages/mailVote/MailVote";
import ClubDetails from "../pages/clubs/clubDetails/ClubDetails";
import EventView from "../pages/eventsManager/viewEvent/EventView";

const routes = [
  {
    path: "/",
    element: <Home />,
    id: "Inicio",
    icon: <HomeIcon />,
    roles: [ROLES.SUPER_ADMIN, ROLES.CLUB, ROLES.FEDERATION, ROLES.REFEJYDA, ROLES.REFEJYDA_IT],
    dashboard: true,
  },
  {
    path: "/federaciones",
    element: <Federations />,
    id: "Federaciones",
    icon: <Apartment />,
    roles: [ROLES.SUPER_ADMIN, ROLES.CLUB, ROLES.FEDERATION, ROLES.REFEJYDA],
    dashboard: true,
  },
  {
    path: "/federaciones/:id",
    element: <AddFederation />,
    id: "Federación",
    roles: [ROLES.SUPER_ADMIN, ROLES.FEDERATION, ROLES.REFEJYDA],
  },
  {
    path: "/federacion/:id",
    element: <AddFederation isDisabled />,
    id: "Federación",
    roles: [ROLES.SUPER_ADMIN, ROLES.CLUB, ROLES.FEDERATION, ROLES.REFEJYDA],
  },
  {
    path: "/federaciones/agregar",
    element: <AddFederation />,
    id: "agregar-federación",
    roles: [ROLES.SUPER_ADMIN],
  },
  {
    path: "/clubs",
    element: <Clubs />,
    id: "Clubs",
    icon: <Store />,
    roles: [ROLES.SUPER_ADMIN, ROLES.CLUB, ROLES.FEDERATION, ROLES.REFEJYDA],
    dashboard: true,
  },
  {
    path: "/clubs/:clubId/editar",
    element: <CreateNewClub />,
    id: "Clubs",
    roles: [ROLES.SUPER_ADMIN, ROLES.CLUB],
  },
  {
    path: "/clubs/:clubId/ver",
    element: <ClubDetails />,
    id: "Clubs",
    roles: [ROLES.SUPER_ADMIN, ROLES.CLUB, ROLES.FEDERATION, ROLES.REFEJYDA],
  },
  {
    path: "/clubs/agregar",
    element: <CreateNewClub />,
    id: "Clubs",
    roles: [ROLES.SUPER_ADMIN, ROLES.FEDERATION],
  },
  {
    path: "/gestor_admins",
    element: <AdminsManager />,
    id: "Gestor de admins",
    icon: <Engineering />,
    roles: [ROLES.SUPER_ADMIN],
    dashboard: true,
  },
  {
    path: "/gestor_admins/agregar",
    element: <AddEditAdmin />,
    id: "Gestor de admins",
    roles: [ROLES.SUPER_ADMIN],
  },
  {
    path: "/gestor_admins/:id",
    element: <AddEditAdmin />,
    id: "Gestor de admins",
    roles: [ROLES.SUPER_ADMIN],
  },
  {
    path: "/admin/:id",
    element: <AddEditAdmin isDisabled />,
    id: "Gestor de admins",
    roles: [ROLES.SUPER_ADMIN],
  },
  {
    path: "/gestor_usuarios",
    element: <UsersManager />,
    id: "Gestor de usuarios",
    icon: <Groups />,
    roles: [ROLES.SUPER_ADMIN, ROLES.CLUB, ROLES.FEDERATION, ROLES.REFEJYDA],
    dashboard: true,
  },
  {
    path: "/users/agregar",
    element: <AddEditUser />,
    id: "Agregar usuario",
    roles: [ROLES.SUPER_ADMIN, ROLES.CLUB, ROLES.FEDERATION],
  },
  {
    path: "/users/:id",
    element: <AddEditUser />,
    id: "Editar usuario",
    roles: [ROLES.SUPER_ADMIN, ROLES.CLUB, ROLES.FEDERATION],
  },
  {
    path: "/user/:id",
    element: <AddEditUser isDisabled />,
    id: "Ver usuario",
    roles: [ROLES.SUPER_ADMIN, ROLES.CLUB, ROLES.FEDERATION, ROLES.REFEJYDA],
  },
  {
    path: "/users/sport-card/:id",
    element: <SportCard />,
    id: "Ficha deportista",
    roles: [ROLES.SUPER_ADMIN, ROLES.CLUB, ROLES.FEDERATION, ROLES.REFEJYDA],
  },
  {
    path: "/gestor_eventos",
    element: <EventsManager />,
    id: "Gestor de eventos",
    icon: <Event />,
    roles: [ROLES.SUPER_ADMIN, ROLES.CLUB, ROLES.FEDERATION, ROLES.REFEJYDA, ROLES.REFEJYDA_IT],
    dashboard: true,
  },
  {
    path: "/gestor_eventos/agregar",
    element: <CreateEditEvent />,
    id: "Gestor de eventos",
    icon: <Event />,
    roles: [ROLES.SUPER_ADMIN, ROLES.CLUB, ROLES.FEDERATION, ROLES.REFEJYDA, ROLES.REFEJYDA_IT],
  },
  {
    path: "/gestor_eventos/simple",
    element: <CreateEditEventSimple />,
    id: "Gestor de eventos simple",
    roles: [ROLES.SUPER_ADMIN, ROLES.CLUB, ROLES.FEDERATION, ROLES.REFEJYDA, ROLES.REFEJYDA_IT],
  },
  {
    path: "/events/:id",
    element: <CreateEditEvent />,
    id: "Gestor de eventos",
    roles: [ROLES.SUPER_ADMIN, ROLES.CLUB, ROLES.FEDERATION, ROLES.REFEJYDA, ROLES.REFEJYDA_IT],
  },
  {
    path: "/event/:id",
    element: <EventView />,
    id: "Ver evento",
    roles: [ROLES.SUPER_ADMIN, ROLES.CLUB, ROLES.FEDERATION, ROLES.REFEJYDA, ROLES.REFEJYDA_IT],
  },
  {
    path: "/inscripciones/:id",
    element: <InscriptionEvent />,
    id: "Inscripción evento",
    roles: [ROLES.SUPER_ADMIN, ROLES.CLUB, ROLES.FEDERATION, ROLES.REFEJYDA, ROLES.REFEJYDA_IT],
  },
  {
    path: "/ranking",
    element: <Ranking />,
    id: "Ranking",
    icon: <BarChart />,
    roles: [ROLES.SUPER_ADMIN, ROLES.CLUB, ROLES.FEDERATION, ROLES.REFEJYDA, ROLES.REFEJYDA_IT],
    dashboard: true,
  },
  {
    path: "/voto-correo",
    element: <MailVote />,
    id: "Voto por correo",
    icon: <HowToVote />,
    roles: [ROLES.SUPER_ADMIN],
    dashboard: true,
  },
];

export default routes;
