import { API_URL, SERVICE } from "../constants/api";
import http from "../constants/axios";
import { formatMuiDate } from "../utils/date";
import { TUserCenso } from "../utils/schemas/censo";
import { FilterState } from "../utils/schemas/listFilters";

export const getUsers = async ({
  type,
  categoryCode,
  organizerId,
  fromDate,
  toDate,
  isActive,
  belt,
  federationId,
  clubId,
  search,
  perPage,
  page,
  eventToInscribe,
  isSportilyActive,
  order,
}: FilterState) => {
  try {
    const params = new URLSearchParams();

    // Filtros principales
    if (type && type !== "") params.append("type", type.toString());
    if (categoryCode && categoryCode !== "") params.append("categoryCode", categoryCode.toString());
    if (organizerId && organizerId !== "") params.append("organizatorId", organizerId.toString());
    if (fromDate) params.append("fromDate", formatMuiDate(fromDate));
    if (toDate) params.append("toDate", formatMuiDate(toDate));
    if (search && search !== "") params.append("search", search.toString());
    if (perPage) params.append("perPage", perPage.toString());
    if (page) params.append("page", page.toString());
    if (eventToInscribe && eventToInscribe !== "")
      params.append("eventToInscribe", eventToInscribe.toString());

    // Filtros adicionales
    if (isActive && isActive !== "") params.append("isActive", isActive.toString());
    if (belt && belt !== "") params.append("belt", belt.toString());
    if (federationId && federationId !== "")
      params.append("federationId", federationId.toString());
    if (clubId && clubId !== "") params.append("clubId", clubId.toString());
    if (isSportilyActive && isSportilyActive !== "")
      params.append("isSportilyActive", isSportilyActive.toString());

    // Parámetros de orden
    if (order?.asc?.length) params.append("asc", order.asc.join(","));
    if (order?.desc?.length) params.append("desc", order.desc.join(","));

    // Realizamos la solicitud HTTP
    const { data } = await http.get(`${API_URL}${SERVICE.USERS}?${params}`);
    return data;
  } catch (error) {
    throw new Error("Error en servidor");
  }
};


export async function getUser(userId: string) {
  try {
    const { data } = await http.get(`${API_URL}${SERVICE.USERS}/${userId}`);

    return data;
  } catch (error) {
    console.log("ERROR =>", error);
    throw new Error("Error en servidor");
  }
}

export async function getAdmins({
  type,
  role,
  clubId,
  federationId,
  search,
  perPage,
  page,
}: FilterState) {
  try {
    const params = new URLSearchParams();
    if (type && type !== "") params.append("type", type.toString());
    if (role && role !== "") params.append("role", role.toString());
    if (clubId && clubId !== "") params.append("clubId", clubId.toString());
    if (federationId && federationId !== "")
      params.append("federationId", federationId.toString());
    if (search && search !== "") params.append("search", search.toString());
    if (perPage) params.append("perPage", perPage.toString());
    if (page) params.append("page", page.toString());

    const { data } = await http.get(`${API_URL}${SERVICE.ADMINS}?${params}`);

    return data;
  } catch (error) {
    console.log("ERROR get admins =>", error);
    throw new Error("Error en servidor");
  }
}

export async function getUserSportCard(userId: string) {
  try {
    const { data } = await http.get(
      `${API_URL}${SERVICE.USERS}/sports-sheet/${userId}`
    );

    return data;
  } catch (error) {
    console.log("ERROR =>", error);
    throw new Error("Error en servidor");
  }
}

export async function checkUserEmail(email: string) {
  try {
    const { data } = await http.get(
      `${API_URL}${SERVICE.USERS}/email-available/${email}`
    );

    return data;
  } catch (error) {
    console.log("ERROR =>", error);
    throw new Error("Error en servidor");
  }
}

export async function checkUserCnr(cnr: string) {
  try {
    const { data } = await http.get(
      `${API_URL}${SERVICE.USERS}/cnr-available/${cnr}`
    );

    return data;
  } catch (error) {
    console.log("ERROR =>", error);
    throw new Error("Error en servidor");
  }
}

export async function validateUser(userId: string) {
  try {
    const { data } = await http.put(
      `${API_URL}${SERVICE.USERS}/validate/${userId}`
    );

    return data;
  } catch (error) {
    throw new Error("Error en servidor");
  }
}

export async function getUsersCenso(filters: FilterState) {
  try {
    const params = new URLSearchParams();
    if (filters.search && filters.search !== "")
      params.append("search", filters.search.toString());
    if (filters.perPage) params.append("perPage", filters.perPage.toString());
    if (filters.page) params.append("page", filters.page.toString());
    if (filters.province && filters.province !== "")
      params.append("province", filters.province.toString());
    if (filters.checked && filters.checked !== "") {
      params.append("isCertified", filters.checked.toString());
    }
    if (filters.duplicate && filters.duplicate !== "") {
      params.append("isDuplicate", filters.duplicate.toString());
    }
    if (filters.subsanado && filters.subsanado !== "") {
      params.append("isSubsanado", filters.subsanado.toString());
    }
    if (filters.dato_erroneo && filters.dato_erroneo !== "") {
      params.append("isDatoErroneo", filters.dato_erroneo.toString());
    }
    if (filters.en_consentimientos && filters.en_consentimientos !== "") {
      params.append("isSinConsentimiento", filters.en_consentimientos.toString());
    }
    if (filters.rechazado && filters.rechazado !== "") {
      params.append("isRechazado", filters.rechazado.toString());
    }

    if (filters.order) {
      if (filters.order.asc.length > 0)
        params.append("asc", filters.order.asc.join(","));
      if (filters.order.desc.length > 0)
        params.append("desc", filters.order.desc.join(","));
    }

    const { data } = await http.get(
      `${API_URL}${SERVICE.USERS}/censo?${params}`
    );

    return data;
  } catch (error) {
    throw new Error("Error en servidor");
  }
}

export async function getUserCensoId(userId: string) {
  try {
    const { data } = await http.get(
      `${API_URL}${SERVICE.USERS}/censo/${userId}`
    );

    return data;
  } catch (error) {
    throw new Error("Error en servidor");
  }
}

export async function validateUserCenso(
  userId: string,
  field: keyof TUserCenso
) {
  try {
    const { data } = await http.put(
      `${API_URL}${SERVICE.USERS}/censo/${userId}`,
      { field }
    );

    return data;
  } catch (error) {
    throw new Error("Error en servidor");
  }
}
