import { Box, FormControl, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useAppSelector } from "../../../../context/hooks/hooks";
import { TUser } from "../../../../utils/schemas/user";
type Props = {
  isDisabled?: boolean;
};

const UserContactInformation = ({ isDisabled }: Props) => {
  const { register, formState, control, watch } = useFormContext<TUser>();
  const { errors } = formState;

  const { countries, autonomies, provinces, municipalities } = useAppSelector(
    (state) => state.formsOptions
  );

  const province = watch("address.provinceCode");

  return (
    <Box className="form-section-container">
      <Typography className="add-user-form-title">Contacto del usuario</Typography>
      <Grid container columnSpacing={4} rowGap={{ xs: 2, md: 4 }} sx={{ my: 2 }}>
        {/* TELEFONO */}
        <Grid item md={6} xs={12} container columnSpacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Typography className="form-input-label">Teléfono</Typography>
              <TextField
                className="input-text form-text-field"
                variant="outlined"
                placeholder="Teléfono*"
                error={!!errors?.phone}
                {...register("phone")}
                InputProps={{
                  readOnly: isDisabled,
                }}
              />
              {errors?.phone && (
                <Typography className="form-input-error">{errors?.phone?.message}</Typography>
              )}
            </FormControl>
          </Grid>
        </Grid>
        {/* EMAIL */}
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Email*</Typography>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="Email*"
              error={!!errors?.email}
              {...register("email")}
              InputProps={{
                readOnly: isDisabled,
              }}
            />
            {errors?.email && (
              <Typography className="form-input-error">{errors?.email?.message}</Typography>
            )}
          </FormControl>
        </Grid>
       {/* DIRECCION */}
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Dirección</Typography>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="Dirección*"
              error={!!errors?.address?.address}
              {...register("address.address")}
              InputProps={{
                readOnly: isDisabled,
              }}
            />
            {errors?.address?.address && (
              <Typography className="form-input-error">
                {errors?.address?.address.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
        {/* PAIS */}
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">País</Typography>
            <Controller
              name="address.countryCode"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  className="input-select form-select-field"
                  error={!!errors?.address?.countryCode}
                  {...field}
                  displayEmpty
                  defaultValue={""}
                  slotProps={{ input: { readOnly: isDisabled } }}
                >
                  <MenuItem value={""}>
                    <em>País</em>
                  </MenuItem>
                  {countries?.map((country) => (
                    <MenuItem key={country.code} value={country.code}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors?.address?.countryCode && (
              <Typography className="form-input-error">
                {errors?.address?.countryCode?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
        {/* AUTONOMÍA */}
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Autonomía</Typography>
            <Controller
              name="address.autonomyCode"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  className="input-select form-select-field"
                  error={!!errors?.address?.autonomyCode}
                  {...field}
                  displayEmpty
                  defaultValue={""}
                  slotProps={{ input: { readOnly: isDisabled } }}
                >
                  <MenuItem value={""}>
                    <em>Autonomía</em>
                  </MenuItem>
                  {autonomies?.map((autonomy) => (
                    <MenuItem key={autonomy.autonomy_code} value={autonomy.autonomy_code}>
                      {autonomy.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors?.address?.autonomyCode && (
              <Typography className="form-input-error">
                {errors?.address?.autonomyCode?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
        {/* PROVINCIA */}
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Provincia</Typography>
            <Controller
              name="address.provinceCode"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  className="input-select form-select-field"
                  error={!!errors?.address?.provinceCode}
                  {...field}
                  displayEmpty
                  defaultValue={""}
                  slotProps={{ input: { readOnly: isDisabled } }}
                >
                  <MenuItem value={""}>
                    <em>Provincia / Region</em>
                  </MenuItem>
                  {provinces
                    ?.filter((province) => province.autonomy_code === watch("address.autonomyCode"))
                    .map((province) => (
                      <MenuItem key={province.province_code} value={province.province_code}>
                        {province.name}
                      </MenuItem>
                    ))}
                </Select>
              )}
            />
            {errors?.address?.provinceCode && (
              <Typography className="form-input-error">
                {errors?.address?.provinceCode?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
        {/* CIUDAD */}
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Ciudad</Typography>
            <Controller
              name="address.municipalityCode"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  className="input-select form-select-field"
                  error={!!errors?.address?.municipalityCode}
                  {...field}
                  displayEmpty
                  defaultValue={""}
                  slotProps={{ input: { readOnly: isDisabled } }}
                >
                  <MenuItem value={""}>
                    <em>Ciudad</em>
                  </MenuItem>
                  {municipalities
                    ?.filter((municipality) => municipality.province_code === province)
                    .map((municipality) => (
                      <MenuItem
                        key={municipality.municipality_code}
                        value={municipality.municipality_code}
                      >
                        {municipality.name}
                      </MenuItem>
                    ))}
                </Select>
              )}
            />
            {errors?.address?.municipalityCode && (
              <Typography className="form-input-error">
                {errors?.address.municipalityCode?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
        {/* CODIGO POSTAL */}
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">CP</Typography>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="CP*"
              error={!!errors?.address?.postalCode}
              {...register("address.postalCode")}
              InputProps={{
                readOnly: isDisabled,
              }}
            />
            {errors?.address?.postalCode && (
              <Typography className="form-input-error">
                {errors?.address.postalCode?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
       
      </Grid>
    </Box>
  );
};

export default UserContactInformation;
