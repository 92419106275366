import React, { useEffect } from "react";
import {
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    Divider,
    List,
    ListItem,
    ListItemText,
    Chip,
    Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useSetPageName from "../../../utils/hooks/useSetPageName";
import useQueryClub from "../../../utils/hooks/useQueryClub";
import { useParams } from "react-router-dom";
import { TClubDetail } from "../../../utils/schemas/club";
import { Instagram, Facebook, WhatsApp, Twitter, YouTube, Apple } from "@mui/icons-material";
import TikTokIcon from "../../../components/icons/TikTokIcon";
import { Link as RouterLink } from "react-router-dom";

const ClubDetails: React.FC = () => {
    useSetPageName([
        { name: "Gestor de Clubes", path: "/clubs" },
        { name: "Vista de club", path: "/clubes/agregar" },
    ]);

    const navigate = useNavigate();
    const { clubId = "" } = useParams();
    const { clubQuery, activate } = useQueryClub(clubId);

    useEffect(() => {
        activate();
        console.log(clubQuery.data); // Verifica qué datos están llegando
    }, [activate, clubQuery.data]);


    const formatDate = (timestamp: number | string | null) =>
        timestamp ? new Date(timestamp).toLocaleDateString() : "Sin datos";


    const parseClubData = (data: any): TClubDetail | undefined => {
        if (!data) return undefined;
    
        return {
            id: data.id || "",
            name: data.name || "Sin nombre",
            shortCode: data.shortCode || "N/A",
            email: data.email || "Sin email",
            phone: data.phone || "Sin teléfono",
            federationId: data.federationId || "",
            federationName: data.federationName || "Sin federación",
            federationCode: data.federationCode || "N/A",
            description: data.description || "Sin descripción",
            latitude: data.latitude || null,
            longitude: data.longitude || null,
            socialMedia: data.socialMedia || null,
            validatedPending: data.validatedPending || false,
            isActive: data.isActive || false,
            lastModifiedAt: data.lastModifiedAt || null,
            createdAt: data.createdAt || null,
            mapsUrl: data.mapsUrl || null,
            website: data.website || null,
            logoUrl: data.logoUrl || null,
            taxName: data.taxName || null,
            iban: data.iban || null,
            cif: data.cif || null,
            weekSchedule: data.weekSchedule || null,
            address: data.address || null,
            users: data.users || [],
        };
    };

    if (clubQuery.isLoading) {
        return <Typography>Cargando datos del club...</Typography>;
    }

    if (clubQuery.isError) {
        return <Typography>Error al cargar los datos del club.</Typography>;
    }

    
    

    const club = parseClubData(clubQuery.data);


    if (!club) {
        console.error("Datos incompletos para el club:", clubQuery.data);
        return <Typography>No se encontraron datos del club.</Typography>;
    }


    const socialLinks = [
        { name: "Instagram", url: club?.socialMedia?.instagramUrl, icon: <Instagram color="primary" /> },
        { name: "Facebook", url: club?.socialMedia?.facebookUrl, icon: <Facebook color="primary" /> },
        { name: "WhatsApp", url: club?.socialMedia?.whatsappUrl, icon: <WhatsApp color="primary" /> },
        { name: "Twitter", url: club?.socialMedia?.twitterUrl, icon: <Twitter color="primary" /> },
        { name: "YouTube", url: club?.socialMedia?.youtubeUrl, icon: <YouTube color="primary" /> },
        { name: "TikTok", url: club?.socialMedia?.tiktokUrl, icon: <TikTokIcon /> },
        { name: "Apple Music", url: club?.socialMedia?.ituneUrl, icon: <Apple color="primary" /> },
    ];

    console.log("Renderizando datos del club:", club);

    return (
        <Card elevation={3}>
            <CardContent>
                {/* Información del club */}
                <Typography variant="h5" gutterBottom>
                    {club.name}{" "}
                    <Chip
                        label={club.isActive ? "Activo" : "Inactivo"}
                        color={club.isActive ? "success" : "default"}
                    />
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                    Código Corto: {club.shortCode || "No disponible"}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                    Federación:{" "}
                    <Link
                        component="button"
                        onClick={() => navigate(`/federacion/${club.federationId}`)}
                    >
                        {club.federationName || "No disponible"}
                    </Link>
                </Typography>
                <Divider sx={{ my: 2 }} />

                {/* Información general */}
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2" gutterBottom>
                            Correo Electrónico:
                        </Typography>
                        <Typography>{club.email || "No disponible"}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2" gutterBottom>
                            Teléfono:
                        </Typography>
                        <Typography>{club.phone || "No disponible"}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" gutterBottom>
                            Descripción:
                        </Typography>
                        <Typography>{club.description || "No disponible"}</Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />

                {/* Dirección */}
                <Box>
                    <Typography variant="h6" gutterBottom>
                        Dirección
                    </Typography>
                    <Typography>{club.address?.address || "No disponible"}</Typography>
                    <Typography>
                        CP: {club.address?.postalCode || "N/A"}, Municipio:{" "}
                        {club.address?.municipalityCode || "N/A"}, Provincia:{" "}
                        {club.address?.provinceCode || "N/A"}
                    </Typography>
                    <Typography>País: {club.address?.countryCode || "N/A"}</Typography>
                </Box>
                <Divider sx={{ my: 2 }} />

                {/* Redes sociales */}
                <Box>
                    <Typography variant="h6" gutterBottom>
                        Redes Sociales
                    </Typography>
                    {socialLinks.filter((link) => link.url) ? (
                        <Grid container spacing={2}>
                            {socialLinks.filter((link) => link.url).map((link) => (
                                <Grid item key={link.name}>
                                    <Link
                                        href={link.url || "#"}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        underline="none"
                                    >
                                        {link.icon}
                                        <Typography variant="body2" color="textSecondary">
                                            {link.name}
                                        </Typography>
                                    </Link>
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <Typography>No disponible</Typography>
                    )}
                </Box>

                <Divider sx={{ my: 2 }} />

                {/* Estado de Validación */}
                <Box>
                    <Typography variant="h6" gutterBottom>
                        Estado de Validación
                    </Typography>
                    <Typography>
                        {club.validatedPending ? "Pendiente de Validación" : "Validado"}
                    </Typography>
                </Box>
                <Divider sx={{ my: 2 }} />

                {/* Última modificación */}
                <Box>
                    <Typography variant="h6" gutterBottom>
                        Última Modificación
                    </Typography>
                    <Typography>{formatDate(club.lastModifiedAt)}</Typography>
                </Box>
                <Divider sx={{ my: 2 }} />

                {/* Usuarios */}
                <Box>
                    <Typography variant="h6" gutterBottom>
                        Usuarios Asociados
                    </Typography>
                    <List>
                        {club.users.map((user) => (
                            <ListItem key={user.id}>
                                <ListItemText
                                    primary={
                                        <Link
                                            component="button"
                                            onClick={() => {
                                                console.log(`Navegando a /user/${user.id}`);
                                                navigate(`/user/${user.id}`);
                                            }}
                                        >
                                            {`${user.name} ${user.surname}`}
                                        </Link>
                                    }
                                    secondary={user.email}
                                />

                            </ListItem>
                        ))}
                    </List>
                </Box>
            </CardContent>
        </Card>
    );
};

export default ClubDetails;
