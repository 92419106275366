import { Badge, Card, CardContent, Button, Typography, Box, Grid, Tabs, Tab } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InscriptionsValidated from "../../components/inscriptions/inscriptionsValidated/InscriptionValidated";
import CreateInscription from "../../components/inscriptions/createInscription/CreateInscription";
import InscriptionsPending from "../../components/inscriptions/inscriptionsPending/InscriptionsPending";
import InscriptionsRejected from "../../components/inscriptions/inscriptionsRejected/InscriptionsRejected";
import useSetPageName from "../../utils/hooks/useSetPageName";
import useInscriptionFilters from "../../utils/hooks/useInscriptionsFilters";
import useQueryClubsList from "../../utils/hooks/useQueryClubList";
import useQueryEventId from "../../utils/hooks/useQueryEventById";
import useQueryFederationsList from "../../utils/hooks/useQueryFederationsList";
import {
  useQueryInscriptionsPending,
  useQueryInscriptionsRejected,
  useQueryInscriptionsValidated,
} from "../../utils/hooks/useQueryInscriptions";
import "./inscriptionEvent.scss";

const InscriptionEvent = () => {
  useSetPageName([
    { name: "Inscripciones", path: "/gestor_eventos" },
    { name: "Lista inscritos", path: "/inscripciones/:id" },
  ]);

  const { id: eventId = "" } = useParams();
  const navigate = useNavigate();
  const [valueTab, setValueTab] = useState(0);
  const [totalUsers, setTotalUsers] = useState<number>(0);

  // Fetch event data and activate
  const { eventData, activate } = useQueryEventId(eventId);

  useEffect(() => {
    activate();
    updateAllFilters("eventId", eventId);
  }, [eventId]);

  const {
    pendingFilters,
    approvedFilters,
    rejectedFilters,
    updateApprovedFilter,
    updatePendingFilter,
    updateRejectedFilter,
  } = useInscriptionFilters();

  const { data: inscriptionsPending, refetch: refetchPending } =
    useQueryInscriptionsPending(pendingFilters);
  const { data: inscriptionsValidated, refetch: refetchValidated } =
    useQueryInscriptionsValidated(approvedFilters);
  const { data: inscriptionsRejected } = useQueryInscriptionsRejected(rejectedFilters);

  const { data: clubs } = useQueryClubsList(pendingFilters.federationId || "");
  const { data: federations } = useQueryFederationsList();

  useEffect(() => {
    if (clubs?.length === 1) updateAllFilters("clubId", clubs[0].id);
    if (federations?.length === 1) updateAllFilters("federationId", federations[0].id);
  }, [clubs, federations]);

  const updateAllFilters = (key: keyof typeof pendingFilters, value: string) => {
    updateApprovedFilter(key, value);
    updatePendingFilter(key, value);
    updateRejectedFilter(key, value);
  };

  // Tab Configuration
  const tabs = [
    {
      label: "Crear inscripción",
      badgeContent: totalUsers,
      component: (
        <CreateInscription
          eventId={eventId}
          categoryCode={eventData?.categoryCode || ""}
          stockCategories={eventData?.stocks}
          refetchInscriptions={refetchPending}
          clubs={clubs}
          federations={federations}
          setTotalUsers={setTotalUsers}
        />
      ),
    },
    {
      label: "Pendientes de validar",
      badgeContent: inscriptionsPending?.total,
      component: (
        <InscriptionsPending
          stockCategories={eventData?.stocks}
          inscriptions={inscriptionsPending}
          refetchInscriptions={refetchPending}
          updatePendingFilter={updatePendingFilter}
          pendingFilters={pendingFilters}
          clubs={clubs}
          federations={federations}
          changeDateLimit={eventData?.weighingChangeLimitDate}
        />
      ),
    },
    {
      label: "Inscritos validados",
      badgeContent: inscriptionsValidated?.total,
      component: (
        <InscriptionsValidated
          inscriptions={inscriptionsValidated}
          refetchInscriptions={refetchValidated}
          stockCategories={eventData?.stocks}
          updateApprovedFilter={updateApprovedFilter}
          filterApproved={approvedFilters}
          clubs={clubs}
          federations={federations}
          changeDateLimit={eventData?.weighingChangeLimitDate}
        />
      ),
    },
    {
      label: "Inscritos Rechazados",
      badgeContent: inscriptionsRejected?.total,
      component: (
        <InscriptionsRejected
          inscriptions={inscriptionsRejected}
          updateRejectedFilter={updateRejectedFilter}
          filterRejected={rejectedFilters}
          clubs={clubs}
          federations={federations}
        />
      ),
    },
  ];

  return (
    <>
      {/* Cabecera de Información del Evento */}
      <Card elevation={3} className="event-info-card" sx={{ marginBottom: "16px", padding: "16px" }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4} >
            <Box
              sx={{
                borderRadius: "8px",
                overflow: "hidden",
                aspectRatio: "3/4",
                width: "100%",
              }}
            >
              <img
                src={eventData?.posterUrl}
                alt={eventData?.name}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box>
              <Typography variant="h5" gutterBottom>
                {eventData?.name || "Nombre del Evento"}
              </Typography>
              <Typography variant="body1">
                Fecha: {new Date(eventData?.startDate || "").toLocaleDateString()} - {new Date(eventData?.endDate || "").toLocaleDateString()}
              </Typography>
              <Typography variant="body1">
                Ubicación: {eventData?.location || "No especificada"}
              </Typography>
              <Typography variant="body1">
                Descripción: {eventData?.description || "No disponible"}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{ marginTop: "16px" }}
                onClick={() => navigate(`/event/${eventId}`)}
              >
                Ver Evento
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Card>

      {/* TABS */}
      <Tabs
        value={valueTab}
        onChange={(_, newValue) => setValueTab(newValue)}
        allowScrollButtonsMobile
        className="inscriptions-tabs"
        TabIndicatorProps={{ className: "inscriptions-tab-indicator" }}
        variant="scrollable"
        scrollButtons="auto"
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            className="inscriptions-tab"
            label={
              <Badge
                badgeContent={tab.badgeContent}
                color="primary"
                className="users-badge-count"
                showZero
                max={9999}
              >
                {tab.label}
              </Badge>
            }
          />
        ))}
      </Tabs>
      {tabs[valueTab]?.component}
    </>
  );
};

export default InscriptionEvent;
