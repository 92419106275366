export const purgeObjectToEmpty = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === "") {
      delete obj[key];
    } else if (typeof obj[key] === "object") {
      purgeObjectToEmpty(obj[key]);
      if (Object.keys(obj[key]).length === 0 && !Array.isArray(obj[key])) {
        delete obj[key];
      }
    }
  });
  return obj;
};

export const sortWeights = <T extends { weight: string }>(categories: T[] | undefined): T[] => {
  // Delete +, - y kg
  const getWeight = (weight: string) => {
    if (!weight) return 0;
    return parseInt(weight?.replace(/[^0-9]/g, ""));
  };

  if (!categories) return [];

  return categories.sort((a, b) => {
    const weightA = getWeight(a.weight);
    const weightB = getWeight(b.weight);

    // Check if a weight is positive or negative
    const isPositiveA = a.weight?.trim().startsWith("+");
    const isPositiveB = b.weight?.trim().startsWith("+");

    // First - then +
    if (isPositiveA && !isPositiveB) return 1;
    if (!isPositiveA && isPositiveB) return -1;

    return weightA - weightB;
  });
};

export const sanitizeBoolean = (value: any): boolean => {
  return value === true || value === 1 || value === "true";
};

export const isAdult = (bornAt?: string): boolean => {
  if (!bornAt) {
    return false; // Si no hay fecha, no es mayor de edad
  }

  const birthDate = new Date(bornAt);
  const today = new Date();

  let age = today.getFullYear() - birthDate.getFullYear();

  const hasBirthdayPassed =
    today.getMonth() > birthDate.getMonth() ||
    (today.getMonth() === birthDate.getMonth() && today.getDate() >= birthDate.getDate());

  if (!hasBirthdayPassed) {
    age--;
  }
  console.log('bornat', bornAt);
  console.log('bornat', age);

  return age >= 18;
};
