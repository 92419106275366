import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { useAppSelector } from "../../../../context/hooks/hooks";
import { TClub } from "../../../../utils/schemas/club";

type Props = {
  fiscal?: boolean;
  isDisabled?: boolean;
};

const Direction = ({ fiscal = false, isDisabled }: Props) => {
  const { register, formState, watch, setValue, control } = useFormContext<TClub>();
  const { errors } = formState;

  //Data for address
  const { countries, autonomies, provinces, municipalities } = useAppSelector(
    (state) => state.formsOptions
  );
  const province = watch("address.provinceCode");
  const fiscalProvince = watch("fiscalAddress.provinceCode");

  const isFiscalAddressSameAsAddress = watch("isFiscalAddressSameAsAddress");

  useEffect(() => {
    if (isFiscalAddressSameAsAddress) {
      const { id, ...addressWithoutId } = watch("address"); // Excluir "id"
      setValue("fiscalAddress", addressWithoutId);
    }
  }, [isFiscalAddressSameAsAddress, setValue, watch]);

  return (
    <>
      <Box className="form-section-container">
        <Typography className="form-section-title">Dirección</Typography>
        <Grid container columnSpacing={4} rowGap={{ xs: 2, md: 4 }} sx={{ my: 2 }}>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <Typography className="form-input-label">Dirección*</Typography>
              <TextField
                className="input-text form-text-field"
                variant="outlined"
                placeholder="Dirección"
                InputProps={{
                  readOnly: isDisabled,
                }}
                error={!!errors?.address?.address}
                {...register("address.address")}
              />
              {errors?.address && (
                <Typography className="form-input-error">{errors?.address?.message}</Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <Typography className="form-input-label">País*</Typography>
              <Controller
                name="address.countryCode"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <Select
                    className="input-select form-select-field"
                    error={!!errors?.address?.countryCode}
                    {...field}
                    displayEmpty
                    defaultValue={""}
                    slotProps={{ input: { readOnly: isDisabled } }}
                  >
                    <MenuItem value={""}>
                      <em>País</em>
                    </MenuItem>
                    {countries?.map((country) => (
                      <MenuItem key={country.code} value={country.code}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors?.address?.countryCode && (
                <Typography className="form-input-error">
                  {errors?.address?.countryCode?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <Typography className="form-input-label">Autonomía*</Typography>
              <Controller
                name="address.autonomyCode"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <Select
                    className="input-select form-select-field"
                    error={!!errors?.address?.autonomyCode}
                    {...field}
                    displayEmpty
                    defaultValue={""}
                    slotProps={{ input: { readOnly: isDisabled } }}
                  >
                    <MenuItem value={""}>
                      <em>Autonomía</em>
                    </MenuItem>
                    {autonomies?.map((autonomy) => (
                      <MenuItem key={autonomy.autonomy_code} value={autonomy.autonomy_code}>
                        {autonomy.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors?.address?.autonomyCode && (
                <Typography className="form-input-error">
                  {errors?.address?.autonomyCode?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <Typography className="form-input-label">Provincia*</Typography>
              <Controller
                name="address.provinceCode"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <Select
                    className="input-select form-select-field"
                    error={!!errors?.address?.provinceCode}
                    {...field}
                    displayEmpty
                    defaultValue={""}
                    slotProps={{ input: { readOnly: isDisabled } }}
                  >
                    <MenuItem value={""}>
                      <em>Provincia / Región</em>
                    </MenuItem>
                    {provinces
                      ?.filter(
                        (province) => province.autonomy_code === watch("address.autonomyCode")
                      )
                      .map((province) => (
                        <MenuItem key={province.province_code} value={province.province_code}>
                          {province.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
              {errors?.address?.provinceCode && (
                <Typography className="form-input-error">
                  {errors?.address.provinceCode?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <Typography className="form-input-label">Ciudad*</Typography>
              <Controller
                name="address.municipalityCode"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <Select
                    className="input-select form-select-field"
                    error={!!errors?.address?.municipalityCode}
                    {...field}
                    displayEmpty
                    defaultValue={""}
                    slotProps={{ input: { readOnly: isDisabled } }}
                  >
                    <MenuItem value={""}>
                      <em>Ciudad</em>
                    </MenuItem>
                    {municipalities
                      ?.filter((municipality) => municipality.province_code === province)
                      .map((municipality) => (
                        <MenuItem
                          key={municipality.municipality_code}
                          value={municipality.municipality_code}
                        >
                          {municipality.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
              {errors?.address?.municipalityCode && (
                <Typography className="form-input-error">
                  {errors?.address.municipalityCode?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <Typography className="form-input-label">CP*</Typography>
              <TextField
                className="input-text form-text-field"
                variant="outlined"
                placeholder="CP"
                InputProps={{
                  readOnly: isDisabled,
                }}
                error={!!errors?.address?.postalCode}
                {...register("address.postalCode")}
              />
              {errors?.address?.postalCode && (
                <Typography className="form-input-error">
                  {errors?.address?.postalCode?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
        </Grid>

        {fiscal && (
          <FormControlLabel
            className="form-checkbox-container"
            control={
              <Checkbox {...register("isFiscalAddressSameAsAddress")} className="form-checkbox" />
            }
            disabled={isDisabled}
            label="Utilizar esta dirección como dirección fiscal"
          />
        )}
      </Box>
      {fiscal && (
        <Box className="form-section-container">
          <Typography className="form-section-title">Dirección fiscal</Typography>
          <Grid container columnSpacing={4} rowGap={{ xs: 2, md: 4 }} sx={{ my: 2 }}>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <Typography className="form-input-label">Dirección*</Typography>
                <TextField
                  className="input-text form-text-field"
                  variant="outlined"
                  placeholder="Dirección"
                  InputProps={{
                    readOnly: isDisabled || isFiscalAddressSameAsAddress,
                  }}
                  error={!!errors?.fiscalAddress?.address}
                  {...register("fiscalAddress.address")}
                />
                {errors?.fiscalAddress && (
                  <Typography className="form-input-error">
                    {errors?.fiscalAddress?.address?.message}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item md={3} xs={12}>
              <FormControl fullWidth>
                <Typography className="form-input-label">País*</Typography>
                <Controller
                  name="fiscalAddress.countryCode"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <Select
                      className="input-select form-select-field"
                      error={!!errors?.fiscalAddress?.countryCode}
                      {...field}
                      displayEmpty
                      defaultValue={""}
                      slotProps={{
                        input: { readOnly: isDisabled || isFiscalAddressSameAsAddress },
                      }}
                    >
                      <MenuItem value={""}>
                        <em>País</em>
                      </MenuItem>
                      {countries?.map((country) => (
                        <MenuItem key={country.code} value={country.code}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors?.fiscalAddress?.countryCode && (
                  <Typography className="form-input-error">
                    {errors?.fiscalAddress?.countryCode?.message}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item md={3} xs={12}>
              <FormControl fullWidth>
                <Typography className="form-input-label">Autonomía*</Typography>
                <Controller
                  name="fiscalAddress.autonomyCode"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <Select
                      className="input-select form-select-field"
                      error={!!errors?.fiscalAddress?.autonomyCode}
                      {...field}
                      displayEmpty
                      defaultValue={""}
                      slotProps={{
                        input: { readOnly: isDisabled || isFiscalAddressSameAsAddress },
                      }}
                    >
                      <MenuItem value={""}>
                        <em>Autonomía</em>
                      </MenuItem>
                      {autonomies?.map((autonomy) => (
                        <MenuItem key={autonomy.autonomy_code} value={autonomy.autonomy_code}>
                          {autonomy.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors?.fiscalAddress?.autonomyCode && (
                  <Typography className="form-input-error">
                    {errors?.fiscalAddress?.autonomyCode?.message}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item md={3} xs={12}>
              <FormControl fullWidth>
                <Typography className="form-input-label">Provincia*</Typography>
                <Controller
                  name="fiscalAddress.provinceCode"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <Select
                      className="input-select form-select-field"
                      error={!!errors?.fiscalAddress?.provinceCode}
                      {...field}
                      displayEmpty
                      defaultValue={""}
                      slotProps={{
                        input: { readOnly: isDisabled || isFiscalAddressSameAsAddress },
                      }}
                    >
                      <MenuItem value={""}>
                        <em>Provincia / Región</em>
                      </MenuItem>
                      {provinces
                        ?.filter(
                          (province) =>
                            province.autonomy_code === watch("address.autonomyCode") ||
                            watch("fiscalAddress.autonomyCode")
                        )
                        .map((province) => (
                          <MenuItem key={province.province_code} value={province.province_code}>
                            {province.name}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
                {errors?.fiscalAddress?.provinceCode && (
                  <Typography className="form-input-error">
                    {errors?.fiscalAddress.provinceCode?.message}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item md={3} xs={12}>
              <FormControl fullWidth>
                <Typography className="form-input-label">Ciudad*</Typography>
                <Controller
                  name="fiscalAddress.municipalityCode"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <Select
                      className="input-select form-select-field"
                      error={!!errors?.fiscalAddress?.municipalityCode}
                      {...field}
                      displayEmpty
                      defaultValue={""}
                      slotProps={{
                        input: { readOnly: isDisabled || isFiscalAddressSameAsAddress },
                      }}
                    >
                      <MenuItem value={""}>
                        <em>Ciudad</em>
                      </MenuItem>
                      {municipalities
                        ?.filter(
                          (municipality) =>
                            municipality.province_code === fiscalProvince || province
                        )
                        .map((municipality) => (
                          <MenuItem
                            key={municipality.municipality_code}
                            value={municipality.municipality_code}
                          >
                            {municipality.name}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
                {errors?.fiscalAddress?.municipalityCode && (
                  <Typography className="form-input-error">
                    {errors?.fiscalAddress.municipalityCode?.message}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item md={3} xs={12}>
              <FormControl fullWidth>
                <Typography className="form-input-label">CP*</Typography>
                <TextField
                  className="input-text form-text-field"
                  variant="outlined"
                  placeholder="CP"
                  InputProps={{
                    readOnly: isDisabled || isFiscalAddressSameAsAddress,
                  }}
                  error={!!errors?.fiscalAddress?.postalCode}
                  {...register("fiscalAddress.postalCode")}
                />
                {errors?.fiscalAddress?.postalCode && (
                  <Typography className="form-input-error">
                    {errors?.fiscalAddress?.postalCode?.message}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default Direction;
