import { uploadImage } from "../services/utils";

//Hay que probarlo
export const transformObjectWithUrls = async (object: any): Promise<any> => {
  const objectToReturn: any = {};
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      const value = object[key];
      if (
        value &&
        typeof value === "string" &&
        value.includes("image") &&
        value.includes("base64")
      ) {
        const imageToUpload = JSON.parse(JSON.stringify(value));
        const mime = imageToUpload.split(";")[0].split(":")[1];
        const base64Image = imageToUpload.split("base64,")[1];
        const imageData = {
          image: "base64," + base64Image,
          mime: mime,
        };
        const imageUrl = await uploadImage(imageData);
        objectToReturn[key] = imageUrl;
      } else if (Array.isArray(value)) {
        objectToReturn[key] = [];
        for (let j = 0; j < value.length; j++) {
          if (
            value[j] &&
            typeof value[j] === "string" &&
            value.includes("image") &&
            value.includes("base64")
          ) {
            const imageToUpload = JSON.parse(JSON.stringify(value));
            const mime = imageToUpload.split(";")[0].split(":")[1];
            const base64Image = imageToUpload.split("base64,")[1];
            const imageData = {
              image: "base64," + base64Image,
              mime: mime,
            };
            const imageUrl = await uploadImage(imageData);
            objectToReturn[key][j] = imageUrl.result;
          } else {
            objectToReturn[key][j] = value[j];
          }
        }
      } else {
        objectToReturn[key] = value;
      }
    }
  }

  return objectToReturn;
};
