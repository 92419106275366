import { Apartment, BarChart, Store, Event } from "@mui/icons-material";
import { Button, Container, Grid, Paper, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/loader/Loading";
import useQueryEvents from "../../utils/hooks/useQueryEvents";
import useSetPageName from "../../utils/hooks/useSetPageName";
import "./home.scss";
import { IMAGE_DEFAULT } from "../../constants/constants";
import { useMemo } from "react";
import { useAppSelector } from "../../context/hooks/hooks";

type Props = {};

const Home = (props: Props) => {
  useSetPageName([{ name: "Inicio", path: "/" }]);
  const navigate = useNavigate();
  const { userInfo } = useAppSelector((state) => state.auth);

  const filters = useMemo(
    () => ({
      page: 1,
      perPage: 10,
      fromDate: new Date(),
      order: { desc: ["start_date"], asc: [""] },
    }),
    []
  );

  const { data, isLoading } = useQueryEvents(filters);
  const showCard = (role: string) => userInfo?.roles.includes(role) || userInfo?.roles.includes("all");

  return (
    <Container sx={{ height: "100dvh", p: 1 }}>
      <Grid container spacing={2} marginBottom={4}>
        {/* Card 1 */}
        {showCard("club") && (
          <Grid item xs={12} md={6} lg={3}>
            <Paper elevation={3} sx={{ padding: 2, textAlign: "center" }}>
              <Store fontSize="large" />
              <Typography variant="h6" sx={{ marginTop: 1 }}>
                Ver mi club
              </Typography>
              <Button
                variant="contained"
                sx={{ marginTop: 2 }}
                onClick={() => navigate("/clubs")}
              >
                Ir a Club
              </Button>
            </Paper>
          </Grid>
        )}

        {/* Card 2 */}
        {showCard("federation") && (
          <Grid item xs={12} md={6} lg={3}>
            <Paper elevation={3} sx={{ padding: 2, textAlign: "center" }}>
              <Apartment fontSize="large" />
              <Typography variant="h6" sx={{ marginTop: 1 }}>
                Ver mi federación
              </Typography>
              <Button
                variant="contained"
                sx={{ marginTop: 2 }}
                onClick={() => navigate("/federaciones")}
              >
                Ir a Federación
              </Button>
            </Paper>
          </Grid>
        )}

        {/* Card 3 */}

        <Grid item xs={12} md={6} lg={3}>
          <Paper elevation={3} sx={{ padding: 2, textAlign: "center" }}>
            <BarChart fontSize="large" />
            <Typography variant="h6" sx={{ marginTop: 1 }}>
              Ver rankings
            </Typography>
            <Button
              variant="contained"
              sx={{ marginTop: 2 }}
              onClick={() => navigate("/ranking")}
            >
              Ir a Rankings
            </Button>
          </Paper>
        </Grid>


        {/* Card 4 */}
        <Grid item xs={12} md={6} lg={3}>
          <Paper elevation={3} sx={{ padding: 2, textAlign: "center" }}>
            <Event fontSize="large" />
            <Typography variant="h6" sx={{ marginTop: 1 }}>
              Ver eventos
            </Typography>
            <Button
              variant="contained"
              sx={{ marginTop: 2 }}
              onClick={() => navigate("/gestor_eventos")}
            >
              Ir a Eventos
            </Button>
          </Paper>
        </Grid>
      </Grid>

      {/* Lista de eventos */}
      <Paper elevation={2} sx={{ padding: "10px", minHeight: "50dvh" }}>
        <Typography
          sx={{
            fontWeight: "bold",
            mb: 1,
            textAlign: "left",
            fontSize: { xs: "1rem", md: "1rem" },
          }}
          variant="h5"
          mt={2}
        >
          Próximos eventos
        </Typography>
        {isLoading ? (
          <Loading />
        ) : (
          <Grid item sx={{ maxHeight: "calc(60vh - 85px)", overflowY: "auto" }}>
            {data?.data?.map((event) => (
              <Grid container item key={event.id} className="event-row">
                <Grid item xs={3}>
                  <img src={event.posterUrl || IMAGE_DEFAULT} alt={event.name} className="event-image" />
                </Grid>
                <Grid item xs={6} paddingLeft={2}>
                  <Typography variant="body1" component="div">
                    {event.name}
                  </Typography>
                  <Typography color="text.secondary">
                    {new Date(event.startDate).toLocaleDateString()} -{" "}
                    {new Date(event.endDate).toLocaleDateString()}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant="outlined"
                    onClick={() => navigate(`/event/${event.id}`)}
                    className="event-link"
                  >
                    Ver Detalles
                  </Button>
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
        <Button
          onClick={() => navigate("/gestor_eventos")}
          color="primary"
          sx={{ width: "100%" }}
          className="home-button"
          startIcon={<Event />}
        >
          Ver más
        </Button>
      </Paper>
    </Container>
  );
};

export default Home;
