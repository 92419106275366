import { Switch } from "@mui/material";
import { styled } from "@mui/system";

const IconSwitch = styled(Switch)(({ theme }) => ({
  width: 50,
  height: 25,
  padding: 0,
  "& .MuiSwitch-track": {
    borderRadius: 16,
    backgroundColor: "rgba(0, 0, 0, 0.25)",
    opacity: 1,
    position: "relative",
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      fontSize: 14,
      color: "#fff",
    },
    "&::before": {
      content:
        'url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 stroke=%22white%22 fill=%22none%22 stroke-linecap=%22round%22 stroke-linejoin=%22round%22 stroke-width=%222%22 viewBox=%220 0 24 24%22 data-icon=%22SvgEye%22 aria-hidden=%22true%22%3E%3Cpath d=%22M12 9.5a2.5 2.5 0 11-1.768.732A2.5 2.5 0 0112 9.5zm0-3.9a9.078 9.078 0 017.05 3.2A10.727 10.727 0 0121 12a10.727 10.727 0 01-1.95 3.2A9.078 9.078 0 0112 18.4a9.078 9.078 0 01-7.05-3.2A10.727 10.727 0 013 12a10.727 10.727 0 011.95-3.2A9.078 9.078 0 0112 5.6z%22%3E%3C/path%3E%3C/svg%3E")',
      left: 2,
      paddingTop: 5,
    },
    "&::after": {
      content:
        'url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2218%22 height=%2218%22 stroke=%22white%22 fill=%22none%22 stroke-linecap=%22round%22 stroke-linejoin=%22round%22 stroke-width=%222%22 viewBox=%220 0 24 24%22 data-icon=%22SvgEyeOff%22 aria-hidden=%22true%22%3E%3Cpath d=%22M13.2 14.2a2.931 2.931 0 01-.6.225 2.466 2.466 0 01-.6.075A2.476 2.476 0 019.5 12a2.575 2.575 0 01.15-.875 1.972 1.972 0 01.45-.725m1.2-4.8h.7a9.078 9.078 0 017.05 3.2A10.727 10.727 0 0121 12a5.332 5.332 0 01-.262.65 8.949 8.949 0 01-.938 1.55M16.9 17a9.48 9.48 0 01-2.188 1 9.154 9.154 0 01-2.712.4 9.078 9.078 0 01-7.05-3.2A10.727 10.727 0 013 12a10.076 10.076 0 01.912-1.788A10.572 10.572 0 017 7M3 3l9 9 9 9%22%3E%3C/path%3E%3C/svg%3E")',
      right: 3,
      paddingTop: 5,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgba(0, 35, 11, 0.2)",
    width: 21,
    height: 21,
    borderRadius: "50%",
    transition: "transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(24px)",
      color: "#fff",
      "+ .MuiSwitch-track": {
        backgroundColor: "#62b265",
        opacity: 1,
      },
    },
  },
}));

type Props = {
  checked: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

export default function SwitchWithIcons({ checked, handleChange, disabled }: Props) {
  return <IconSwitch checked={checked} onChange={handleChange} disabled={disabled}
/>;
}
