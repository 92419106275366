import { Grid, Paper, Typography, Box, Chip } from "@mui/material";
import { GENRES } from "../../../constants/constants";
import { sortWeights } from "../../../utils/utils";
import "./StocksTable.scss";

interface TEventStock {
  stock?: number;
  categoryId?: string;
  categoryName?: string;
  sportCode?: string;
  genre?: string;
  weight?: string;
  eventId?: string;
  inscribedUsers?: string | number;
  id?: string;
  approved?: number;
  reserved?: number;
}

interface Props {
  stocks: TEventStock[];
}

const StocksTable: React.FC<Props> = ({ stocks }) => {
  const renderGridForGenre = (genre: string) => {
    const filteredStocks = stocks.filter(
      (stock): stock is TEventStock & { weight: string } => stock.genre === genre && stock.weight !== undefined
    );
    const orderedStocks = sortWeights(filteredStocks);

    // Check if a genre has stock
    const hasStock = filteredStocks.some((stock) => stock.stock && stock.stock > 0);
    if (!hasStock) {
      return null;
    }

    const genreLabel =
      genre === GENRES.MEN ? "Masculino" : genre === GENRES.WOMEN ? "Femenino" : "Mixto";
    const genreColor =
      genre === GENRES.MEN ? "#15a9d3" : genre === GENRES.WOMEN ? "#e79356" : "#6cb001";

    return (
      <Box
        key={genre}
        sx={{
          marginBottom: "16px",
          padding: "16px",
          borderRadius: "8px",
          backgroundColor: genreColor,
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", marginBottom: "8px", textAlign: "center" }}
        >
          {genreLabel}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "nowrap",
            overflowX: "auto",
            gap: "8px",
            padding: "8px 0",
          }}
        >
          {orderedStocks.map((stock, index) => (
            <Paper
              key={index}
              elevation={3}
              sx={{
                minWidth: "120px",
                padding: "8px",
                textAlign: "center",
                flex: "0 0 auto",
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
              >
                {stock.weight}
              </Typography>
              <Typography variant="body2">
                {stock.inscribedUsers || 0}/{stock.stock || 0} inscritos
              </Typography>
              {"reserved" in stock && (
                <Typography variant="body2" color="textSecondary">
                  Reservas: {stock.reserved || 0}
                </Typography>
              )}
            </Paper>
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ padding: "16px" }}>
      {Object.values(GENRES).map((genre) => renderGridForGenre(genre))}
    </Box>
  );
};

export default StocksTable;
